import './App.css';
import {useTelegram} from "./hooks/useTelegram";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import Main from "./components/pages/main/main";
import Models from "./components/pages/models/models";
import Model from "./components/pages/model/model";

function App() {
  const {webApp} = useTelegram()
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    webApp.MainButton.hide();
    navigate(-1)
  },[navigate])

  const onMainClick = useCallback(() => {
    window.open('https://t.me/Nikitamosh')
  }, [navigate])

  useEffect(() => {
    webApp.ready()
    webApp.BackButton.onClick(onBackClick)
    webApp.MainButton.setText("Написать нам")
    webApp.MainButton.onClick(onMainClick)
    return () => {
      webApp.BackButton.offClick(onBackClick)
      webApp.MainButton.offClick(onMainClick)
    };
  }, [webApp])

  return (
      <div className="App">
        <Routes>
          <Route index element={<Main/>}/>
          <Route path='/catalog' element={<Models/>}/>
          <Route path='/model' element={<Model/>}/>
        </Routes>
      </div>
  )
}

export default App;
