import React, {useEffect, useState} from 'react';
import classes from "./main.module.css";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import {api_address_server} from "../../../conf";
import {getCatalog} from "../../../utils/api";
import {useNavigate} from "react-router-dom";

const Main = () => {
    const [companies, setCompanies] = useState([])
    const navigate = useNavigate();

    useEffect(()=>{
        const getCompanies = async () => {
            const res = await getCatalog();
            if(res.status === 'OK'){
                setCompanies(res.result)
            }
        }
        getCompanies()
    },[])

    return (
        <TelegramScreen className={classes._} showbackbutton={false}>
            <div className={classes.block}>
                {companies.map((item,number)=>
                    <div className={classes.item+' '+(number!==0 && classes.block_1)}
                         key={item.id}
                         onClick={()=>{navigate('/catalog?brand='+(item.id))}}
                    >
                        <div className={classes.logoBlock}>
                            <img alt={''} className={classes.logo} src={api_address_server+item.logo}/>
                        </div>
                        <div className={classes.text}>
                            {item.name}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                            <path d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683418 12.0976 1.31658 12.0976 1.70711 11.7071L6.70711 6.70711C7.09763 6.31658 7.09763 5.68342 6.70711 5.29289L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L4.58579 6L0.292893 10.2929Z" fill="#999999"/>
                        </svg>
                    </div>)}
            </div>
        </TelegramScreen>
    );
};

export default Main;