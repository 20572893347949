import React, {useEffect, useState} from 'react';
import classes from "./models.module.css";
import TelegramScreen from "../../common/telegramScreen/telegramScreen";
import {getModels} from "../../../utils/api";
import {api_address_server} from "../../../conf";
import {useNavigate} from "react-router-dom";
import {useTelegram} from "../../../hooks/useTelegram";

const Models = () => {
    const [selectCompany, setSelectCompany] = useState(0)
    const [nameCompany, setNameCompany] = useState('')
    const [companies, setCompanies] = useState([])
    const [cars, setCars] = useState([])
    const navigate = useNavigate();
    const {webApp} = useTelegram()

    const currency = new Map();
    currency.set('RUB', '₽');
    currency.set('USD', '$');
    currency.set('CNY', '¥');

    useEffect(()=>{
        const getModelsT = async () => {
            const brand = window.location.search.split('=')[1]
            if(brand)
                setSelectCompany(parseInt(brand?brand:0))
            const res = await getModels(brand?brand:0);
            if(res.status === 'OK'){
                setCars(res.result)
            }
        }
        getModelsT()
    },[])

    return (
        <TelegramScreen className={classes._} showbackbutton={true}>
            {cars.map(item=>
                <div key={item.id} className={classes.block} onClick={()=>{navigate('/model?id='+(item.id)); webApp.MainButton.show();}}>
                    <div className={classes.block2}>
                        <div className={classes.imageBlock}>
                            <img className={classes.image} src={api_address_server+item.photo1} alt={''}/>
                        </div>
                        <div className={classes.name}>{item.name}</div>
                        <div className={classes.price}>{item.price} {currency.get(item.currency)}</div>
                    </div>
                </div>
            )}
        </TelegramScreen>
    );
};

export default Models;