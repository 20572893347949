import React, {useEffect, useState} from 'react';
import classes from "./model.module.css";
import {getModel} from "../../../utils/api";
import Slider from "react-slick";
import {api_address_server} from "../../../conf";

const Model = () => {
    const [car, setCar] = useState({})
    const [image, setImage] = useState([])
    const [numberImage, setNumberImage] = useState(0)

    const currency = new Map();
    currency.set('RUB', '₽');
    currency.set('USD', '$');
    currency.set('CNY', '¥');

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: classes.sliderBlock
    };

    useEffect(()=>{
        const getModelT = async () => {
            const res = await getModel(window.location.search.split('=')[1]);
            if(res.status === 'OK'){
                setCar(res.result[0])
                let temp = []
                temp.push(res.result[0].photo1)
                temp.push(res.result[0].photo2)
                temp.push(res.result[0].photo3)
                temp.push(res.result[0].photo4)
                temp.push(res.result[0].photo5)
                temp.push(res.result[0].photo6)
                temp.push(res.result[0].photo7)
                temp.push(res.result[0].photo8)
                temp.push(res.result[0].photo9)
                temp.push(res.result[0].photo10)
                temp = temp.filter(item => item !== '')
                setImage(temp)
            }
        }
        getModelT();
    },[])

    return (
        <div className={classes.block}>
            <div className={classes.slider}>
                <Slider {...settings}>
                    {image?.map(i=>
                            //<div key={i.id} className={classes.imageSquare}>
                            <div className={classes.imageBlock2} key={i}>
                                <div style={{backgroundImage:'url('+api_address_server+i+')'}} className={classes.imageBlock}></div>
                            </div>
                        // </div>
                        //<img className={classes.imageBlock} src={address_server+i.url} alt={''}/>
                        // <div key={i.id} style={{backgroundImage:'url('+address_server+i.url+')'}} className={classes.imageBlock}>{i.id}</div>
                    )}
                </Slider>
            </div>
            <div className={classes.name} style={{fontSize:'16px'}}>{car.name}</div>
            <div className={classes.infoBlock}>
                <div className={classes.price} style={{textAlign:'center'}}>ХАРАКТЕРИСТИКИ</div>
                <table className={classes.name}>
                    <tbody>
                        {car.size && <tr>
                            <td className={classes.price}>Размеры:</td>
                            <td className={classes.name}>{car.size}</td>
                        </tr>}
                        {car.wheelbase &&
                        <tr>
                            <td className={classes.price}>Колесная база:</td>
                            <td className={classes.name}>{car.wheelbase}</td>
                        </tr>}
                        {car.wheels &&
                        <tr>
                            <td className={classes.price}>Колеса:</td>
                            <td className={classes.name}>{car.wheels}</td>
                        </tr>}
                        {car.battery &&
                        <tr>
                            <td className={classes.price}>Батарея:</td>
                            <td className={classes.name}>{car.battery}</td>
                        </tr>}
                        {car.power &&
                        <tr>
                            <td className={classes.price}>Мощность:</td>
                            <td className={classes.name}>{car.power}</td>
                        </tr>}
                        {car.acceleration &&
                        <tr>
                            <td className={classes.price}>Разгон:</td>
                            <td className={classes.name}>{car.acceleration}</td>
                        </tr>}
                        {car.speed &&
                        <tr>
                            <td className={classes.price}>Максимальная скорость:</td>
                            <td className={classes.name}>{car.speed}</td>
                        </tr>}
                        {car.range &&
                        <tr>
                            <td className={classes.price}>Дальность хода по циклу NEDC:</td>
                            <td className={classes.name}>{car.range}</td>
                        </tr>}
                        {car.currency &&
                        <tr>
                            <td className={classes.price}>Цена:</td>
                            <td className={classes.name}>{car.price} {currency.get(car.currency)}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Model;