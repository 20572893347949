import {api_address_server} from "../conf";
export async function getInfo(){
    return await (await fetch(api_address_server+'get_info')).json();
}

export async function getCatalog(){
    return await (await fetch(api_address_server+'get_company')).json();
}

export async function getModels(id){
    if(id===0)
        return await (await fetch(api_address_server+'get_models?company')).json();
    return await (await fetch(api_address_server+'get_models?company='+id.toString())).json();
}

export async function getModel(id){
    return await (await fetch(api_address_server+'get_model?id='+id.toString())).json();
}
